/* ScollBar CSS */

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* End ScollBar CSS */


/* Tailwind CSS ScollBar  */

.journal-scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    cursor: pointer;
    /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
.journal-scroll::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
    /*background: red;*/
}
.journal-scroll::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
    /*outline: 1px solid slategrey;*/
}






/* Tailwind CSS  End ScollBar  */


/* Page Not Found CSS */

.st0 {
    fill: #fff
}

.st1 {
    fill: #b5dfea
}

.st2 {
    opacity: .55;
    fill: #90cedd
}

.st3 {
    fill: #d7f0f9
}

.st4 {
    fill: #0582c1
}

.st5 {
    fill: #79c9e8
}

.st6 {
    fill: #ffbf4d
}

.st7 {
    fill: #00668e
}

.st8 {
    fill: #05556d
}

.st9 {
    fill: #f98d3d
}

.st10 {
    fill: #ed701b
}

.st11 {
    fill: none
}

.st12 {
    fill: #efaa3a
}

.st13 {
    opacity: .29;
    fill: #f98d2b
}

.st14 {
    fill: #49b4d6
}

.st15 {
    fill: #ff9f50
}

.st16 {
    fill: #f77e2d
}

.st17 {
    opacity: .55;
    fill: url(#SVGID_1_)
}

/* End Page Not Found CSS */